import { useAppSelector } from '@/redux/hooks';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import Header from '@/layout/header';
import { useRouter } from 'next/router';
import BackIcon from '@/assets/svg/Back';
import { useMain } from '@/contexts/main';
import { NextPageContext } from 'next';
import SEO from '@/helpers/seo';
import HeadComp from '@/components/Head';
import SkeletonHome from './skeletonHome/SkeletonHome';
import useTranslation from '@/hooks/cardapioUseTranslation';

export default function Home({ seo }: any) {
	const { list, loading } = useAppSelector(state => state.home);
	const auth = useAppSelector(state => state.auth);
	const router = useRouter();
	const { setIsStoreOpen } = useMain();
	const [openNormal, setOpenNormal] = useState(false);
	const {returnTranslation} = useTranslation();

	useEffect(() => {
		if (auth.client?.config_cliente.direcionamento) {
			router.push(auth.client.config_cliente.direcionamento);
		} else if (list) {
			if (list.retorno === false || !list.conteudo) {
				router.push('/cardapio');
			}
		}
	}, [list]);

	useEffect(() => {
		if (auth.client) {
			if (auth?.client?.config_cliente?.comanda_sem_qrcode  && auth?.client?.config_cliente?.usa_ticket && auth?.client?.config_cliente?.tipo_empresa === 'M' ) {
				router.push('/busca', '', { shallow: true });
			} else {
				setOpenNormal(true);
			}
		}
	}, [auth.client]);

	useEffect(() => {
		setIsStoreOpen(true);
	}, []);

	return (
		<>
			{seo && seo.retorno && seo.config_cliente && (
				<HeadComp
					title={seo.config_cliente.empresa || ''}
					img={seo.config_cliente.img_logo || ''}
					facebook={seo.config_cliente.pixel_facebook || ''}
					globalSiteId={seo.config_cliente.global_site_id || ''}
					globalSiteTag={seo.config_cliente.global_site_tag || ''}
				/>
			)}
			<Header />
			{loading ? (
				<SkeletonHome />
			) : (
				<>
					{list && openNormal && (

						<main id="home">
							<div className="list">
								{list.conteudo?.map(banner => (
									<div key={banner.id} onClick={() => (list.tipo === 'M' ? setIsStoreOpen(!!banner.aberta) : null)} className="w-full">
										<Link
											href={{
												pathname: '/cardapio',
												query: { departmentId: banner.id },
											}}
											className={`card ${banner.nome && 'cardWithName'}`}
											
											style={{
												backgroundImage: `url(${list.tipo === 'M' ? banner.imagem_fundo : banner.imagem})`,
											}}>
											<div className={`cardContent ${(banner.nome || list.tipo === 'M') && 'cardContentWithName'}`}>
												<div className="flex items-center">
													{list.tipo === 'M' && banner.imagem && (
														<div
															className="logo"
															style={{
																backgroundImage: `url(${banner.imagem})`,
															}}
														/>
													)}
													<div>
														{banner.nome && <h2 className="cardTitle">{banner.nome}</h2>}
														{list.tipo === 'M' && <span className={`storeStatus ${!banner.aberta && 'closed'}`}>{banner.aberta ? returnTranslation('openOrders') : returnTranslation('consultation') }</span>}
													</div>
												</div>

												<div className="cardBtn">
													<BackIcon />
												</div>
											</div>
										</Link>
									</div>
								))}
							</div>
							{/* <Button className="btnAll">Ver todos</Button> */}
						</main>
					)}
				</>
			)}
		</>
	);
}

export const getServerSideProps = async (context: NextPageContext) => {
	let seo = await SEO(context);
	return seo;
};
